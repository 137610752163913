import React from 'react';

import { useIntl } from 'gatsby-plugin-react-intl';
import { graphql } from 'gatsby';
import { PrivacyPolicyQuery } from '../../graphql-types';
import { LegalsTemplate, LegalsTemplateProps } from '../templates/legals.template';

import SEO from '../components/seo';
import { parseLocale } from '../locale';
import { useLocalizedSlugs } from '../hooks/useLocalizedSlugs';

export const query = graphql`
  query PrivacyPolicy($language: String) {
    cms {
      privacyPolicy(locale: $language) {
        content
      }
    }
  }
`;

const PrivacyPolicyPage: React.FC<{ data: PrivacyPolicyQuery }> = ({ data }) => {
  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  useLocalizedSlugs({
    slugs: [
      { locale: 'en', slug: 'privacy-policy' },
      { locale: 'fr', slug: 'politique-de-confidentialite' },
    ],
  });

  if (!data?.cms?.privacyPolicy) return null;

  const termsProps = mapQueryToPrivacyPolicyProps(data);
  return (
    <>
      <SEO lang={locale} title={intl.formatMessage({ id: 'privacy_policy' })} />
      <LegalsTemplate {...termsProps} />
    </>
  );
};

function mapQueryToPrivacyPolicyProps(data: PrivacyPolicyQuery): LegalsTemplateProps {
  return {
    markdown: data.cms?.privacyPolicy?.content ?? '',
  };
}

export default PrivacyPolicyPage;
